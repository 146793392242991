<template>
    <div class="c-app flex-row align-items-center">
        <CContainer>
            <CRow class="justify-content-center">
                <CCol md="8">
                    <CCardGroup>
                        <CCard class="p-4">
                            <CCardBody>
                                <CForm>
                                    <h1>Giriş yap</h1>
                                    <p class="text-muted">Yönetici hesabınla giriş yap</p>
                                    <CInput
                                        placeholder="e-posta adresi"
                                        autocomplete="email"
                                        v-model="email"
                                    >
                                        <template #prepend-content><CIcon name="cil-user"/></template>
                                    </CInput>
                                    <CInput
                                        placeholder="Parola"
                                        type="password"
                                        autocomplete="password"
                                        v-model="password"
                                    >
                                        <template #prepend-content><CIcon name="cil-lock-locked"/></template>
                                    </CInput>
                                    <CRow>
                                        <CCol col="6" class="text-left">
                                            <CButton color="primary" class="px-4" v-on:click="login()">Giriş Yap</CButton>
                                        </CCol>
                                    </CRow>
                                </CForm>
                            </CCardBody>
                        </CCard>
                    </CCardGroup>
                </CCol>
            </CRow>
        </CContainer>
    </div>
</template>

<script>
import axios from 'axios';


export default {
    name: 'Login',

    data() {
        return {
            email: '',
            password: '',
        };
    },

    methods: {
        async login() {
            try {
                const response = await axios({
                    url: '/authentication/login',
                    method: 'post',
                    data: {
                        email: this.email,
                        password: this.password,
                    },
                });

                if (response.status === 200) {
                    axios.defaults.headers['X-AccessToken'] = response.data.token;
                    localStorage.setItem('accessToken', response.data.token);
                    this.$router.push('/dashboard');
                } else {
                    console.error(response.data);
                    alert('Kullanıcı adı veya parola hatalı');
                }
            } catch (error) {
                console.error(error);
                alert('Kullanıcı adı veya parola hatalı');
            }
        },
    }
}
</script>
